import { IApiUserDto } from '../api-model/auth.api-model';
import { AuthInfo } from '../model/auth-info';

export class AuthInfoAdapter {
  adapt(data: IApiUserDto): AuthInfo {
    return {
      username: data.username,
      fullName: data.full_name,
      email: data.email,
      customerId: data.customer,
      stalePassword: data.stale_password,
      isStaff: data.is_staff,
      isActive: data.is_active,
      isFundraiser: data.is_fundraiser,
      isTeamLeader: data.is_teamleader,
      isCoach: data.is_coach,
      isCampaignManager: data.is_campaignmanager,
      isCustomerAdmin: data.is_customeradmin,
      isOrganizationUser: data.is_organization_user,
      isPetitionCampaignManager: data.is_petitioncampaignmanager,
      isPetitionCustomerAdmin: data.is_petitioncustomeradmin,
      isRecruiter: data.is_recruiter,
      isHeadRecruiter: data.is_head_recruiter,
      isJobScout: data.is_jobscout,
      isCallingAgent: data.is_calling_agent,
      isAgencyAdminOne: data.is_agency_admin_one,
    };
  }

  /**
   * @returns
   * An empty `AuthInfo` object
   */
  makeEmpty(): AuthInfo {
    return {
      username: null,
      fullName: null,
      email: null,
      customerId: null,
      stalePassword: false,
      isStaff: false,
      isActive: false,
      isFundraiser: false,
      isTeamLeader: false,
      isCoach: false,
      isCampaignManager: false,
      isCustomerAdmin: false,
      isOrganizationUser: false,
      isPetitionCampaignManager: false,
      isPetitionCustomerAdmin: false,
      isRecruiter: false,
      isHeadRecruiter: false,
      isJobScout: false,
      isCallingAgent: false,
      isAgencyAdminOne: false,
    };
  }
}
