import { Injectable } from '@angular/core';
import { UserApi } from '@fmnts/api/auth';
import { createHttpRequestEffectHandler } from '@fmnts/common/store';
import { fromHttpOrGeneralError } from '@formunauts/shared/errors/domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap } from 'rxjs';
import { AuthApiActions } from './auth.actions';

/**
 * Effects for user api.
 */
@Injectable()
export class UserApiEffects {
  /**
   * Fetches Auth Info.
   */
  authInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthApiActions.authInfo.request,
        // Also request auth info after a successful login
        AuthApiActions.authenticate.success,
        AuthApiActions.refresh.success,
      ),
      exhaustMap(
        createHttpRequestEffectHandler(AuthApiActions.authInfo, {
          run: () => this.userApi.fetchAuthInfo(),
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private userApi: UserApi,
  ) {}
}
