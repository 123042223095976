import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api';
import { AuthClientConfig } from '@fmnts/api/auth';
import { UrlRoute, UrlTree } from '@fmnts/core/url';
import {
  AuthInterceptorActions,
  selectSession,
} from '@fmnts/shared/auth/data-access';
import { Store } from '@ngrx/store';
import { concatMap, first, iif, map, tap, type Observable } from 'rxjs';

type Method =
  | 'DELETE'
  | 'GET'
  | 'HEAD'
  | 'JSONP'
  | 'OPTIONS'
  | 'PATCH'
  | 'POST'
  | 'PUT';

/**
 * Either a set of methods that are allowed or all methods.
 */
type AllowedMethods = Method[] | '*';

/**
 * Routes that are allowed to be used without authentication.
 */
const allowRoutes: UrlRoute<AllowedMethods>[] = [
  {
    path: '/o/token',
    value: ['POST'],
  },
  {
    path: '/api/next/verify',
    value: ['POST'],
    children: [
      {
        path: ':hash',
        value: '*',
      },
    ],
  },
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * An URL tree with URLs that don't need auth
   */
  private readonly allowUrls = UrlTree.fromRoutes(allowRoutes);

  private readonly session$ = this.store.select(selectSession);

  constructor(
    private authClientConfig: AuthClientConfig,
    private apiConfig: ApiConfigService,
    private store: Store,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return iif(
      () => this.needsAuthHeaders(req),
      // Handle requests that need authentication
      this.session$.pipe(
        first(),
        map((session) =>
          session
            ? req.clone({
                setHeaders: this.authClientConfig.getAuthHeader(session),
              })
            : req,
        ),
        concatMap((_req) => next.handle(_req)),
      ),
      // Handle requests that don't need authentication
      next.handle(req),
    ).pipe(
      tap({
        error: (err) => {
          this.handleError(req, err);
        },
      }),
    );
  }

  private handleError(req: HttpRequest<any>, err: unknown): void {
    if (this.shouldRedirectToLogin(req, err as HttpErrorResponse)) {
      this.store.dispatch(AuthInterceptorActions.unauthorizedRequested());
    }
  }

  /**
   * @returns
   * `true` if the user should be redirected to the login page
   */
  private shouldRedirectToLogin(req: HttpRequest<any>, err: HttpErrorResponse) {
    const { status } = err;
    const { url } = req;
    // First, check the http status code received
    if (![400, 401, 403].includes(status)) {
      return false;
    }

    const { pathname } = this.asUrl(url);

    // We only need to redirect to login, if errors occured on our endpoints
    if (!this.apiConfig.isOurEndpoint(url)) {
      return false;
    }

    // We need to redirect to login, if a 400, 401 or 403 error occurs on Auth-endpoints
    if (this.isAuthEndpoint(pathname) && [400, 401, 403].includes(status)) {
      if (req.body instanceof FormData) {
        return req.body.get('grant_type') === 'refresh_token';
      }
    }

    return status === 401 && this.isSecuredEndpoint(pathname, req.method);
  }

  // Test if the URL is from the Authentification Endpoint ('/o/token/')
  private isAuthEndpoint(path: string): boolean {
    return path.startsWith(this.authClientConfig.authTokenUrl);
  }

  /**
   * @param pathname The pathname to check. Should not be the complete URL.
   * @param method The method to check.
   *
   * @returns
   * `true`, if the requested endpoint is secured
   */
  private isSecuredEndpoint(pathname: string, method: string): boolean {
    const allowUrl = this.allowUrls.root.findByPath(pathname);

    // Only if the endpoint is allowed, we treat it as an unsecured endpoint
    if (
      allowUrl &&
      (allowUrl.value === '*' || allowUrl.value.includes(method as Method))
    ) {
      return false;
    }

    return true;
  }

  /**
   * @param req The request
   *
   * @returns
   * `true`, if the authentication headers are needed for this request
   */
  private needsAuthHeaders(req: HttpRequest<any>): boolean {
    const { url, method } = req;

    return (
      this.apiConfig.isOurEndpoint(url) &&
      this.isSecuredEndpoint(this.asUrl(url).pathname, method)
    );
  }

  /**
   * @param url Absolute or relative URL. Relative URLs will be relative to `window.location.origin`.
   *
   * @returns
   * URL object for the given `url`.
   */
  private asUrl(url: string): URL {
    try {
      return new URL(url);
    } catch (e) {
      // eslint-disable-next-line no-empty
    }

    return new URL(url, window.location.origin);
  }
}
