import { CustomerId } from '@fmnts/api/shared';
import { Role } from './role';

export interface AuthInfo {
  /** Username */
  username: string | null;
  /** Full name of the user */
  fullName: string | null;
  /** User email */
  email: string | null;
  /**
   * ID of the customer.
   * May be `null` if it is an organization account.
   */
  customerId: CustomerId | null;
  /** If `true`, the password is considered stale and should be updated. */
  stalePassword: boolean;
  isStaff: boolean;
  isActive: boolean;
  isFundraiser: boolean;
  isTeamLeader: boolean;
  isCoach: boolean;
  isCampaignManager: boolean;
  isCustomerAdmin: boolean;
  isOrganizationUser: boolean;
  isPetitionCampaignManager: boolean;
  isPetitionCustomerAdmin: boolean;
  isRecruiter: boolean;
  isHeadRecruiter: boolean;
  isJobScout: boolean;
  isCallingAgent: boolean;
  isAgencyAdminOne: boolean;
}

export function rolesForAuthInfo(authInfo: AuthInfo): Role[] {
  const roles: Role[] = [];

  if (!authInfo || !authInfo.isActive) {
    return roles;
  }

  if (authInfo.isAgencyAdminOne) {
    roles.push(Role.AgencyAdminOne);
  }

  if (authInfo.isCallingAgent) {
    roles.push(Role.CallingAgent);
  }

  if (authInfo.isCampaignManager) {
    roles.push(Role.CampaignManager);
  }

  if (authInfo.isCoach) {
    roles.push(Role.Coach);
  }

  if (authInfo.isCustomerAdmin) {
    roles.push(Role.CustomerAdmin);
  }

  if (authInfo.isFundraiser) {
    roles.push(Role.Fundraiser);
  }

  if (authInfo.isHeadRecruiter) {
    roles.push(Role.HeadRecruiter);
  }

  if (authInfo.isJobScout) {
    roles.push(Role.JobScout);
  }

  if (authInfo.isOrganizationUser) {
    roles.push(Role.Organization);
  }

  if (authInfo.isPetitionCampaignManager) {
    roles.push(Role.PetitionCampaignManager);
  }

  if (authInfo.isPetitionCustomerAdmin) {
    roles.push(Role.PetitionCustomerAdmin);
  }

  if (authInfo.isRecruiter) {
    roles.push(Role.Recruiter);
  }

  if (authInfo.isTeamLeader) {
    roles.push(Role.TeamLeader);
  }

  return roles;
}
