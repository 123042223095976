import { Injectable } from '@angular/core';
import { AuthInfo, Permission, Role, rolesForAuthInfo } from '@fmnts/api/auth';

type RolesPerPermissionMapping = { [P in Permission]: Role[] };
type PermissionRoleMap = Map<`${Permission}`, Set<Role>>;

/**
 * Helper to create a map from roles per permission
 */
function createPermissionRoleMap(
  rolesPerPermission: RolesPerPermissionMapping,
): PermissionRoleMap {
  return new Map(
    Object.entries(rolesPerPermission).map(([permission, roles]) => [
      permission as `${Permission}`,
      new Set(roles),
    ]),
  );
}

/**
 * Map that holds which roles are allowed for a certain permission.
 */
const ROLES_PER_PERMISSION: PermissionRoleMap = createPermissionRoleMap({
  [Permission.Login]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.HeadRecruiter,
    Role.JobScout,
    Role.Recruiter,
    Role.TeamLeader,
  ],
  [Permission.ViewDashboard]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewDashboardActivity]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewDashboardPerformance]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivities]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivitiesDonations]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivitiesAchievements]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivitiesDonorFeeback]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewTeamList]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.ViewTeam]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.CreateTeam]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.EditTeam]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.DeleteTeam]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],

  [Permission.ViewFundraiserList]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.ViewFundraiser]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.CreateFundraiser]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.EditFundraiser]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.PromoteFundraiserToCoach]: [Role.Coach, Role.CustomerAdmin],
  [Permission.PromoteFundraiserToTeamLeader]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.DeleteFundraiser]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],

  [Permission.ViewDonationList]: [Role.AgencyAdminOne, Role.CustomerAdmin],
  [Permission.ViewDonation]: [Role.AgencyAdminOne, Role.CustomerAdmin],
  [Permission.CreateDonation]: [Role.CustomerAdmin],
  [Permission.EditDonation]: [Role.CustomerAdmin],
  [Permission.DeleteDonation]: [Role.CustomerAdmin],

  [Permission.ViewUnfinishedDonationList]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewUnfinishedDonation]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.CreateUnfinishedDonation]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.EditUnfinishedDonation]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.DeleteUnfinishedDonation]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewDonationStatistics]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewCampaignStatistics]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewFundraiserStatistics]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocationStatistics]: [
    Role.AgencyAdminOne,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewTeamStatistics]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewLocationList]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocation]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.EditLocation]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocationCommentList]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.CreateLocationComment]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocationReporting]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.Organization,
  ],
  [Permission.CreateLocation]: [Role.CampaignManager, Role.CustomerAdmin],

  [Permission.ViewDonorFeedbackEvaluation]: [
    Role.AgencyAdminOne,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.EditDonorFeedbackComment]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewPetitions]: [Role.PetitionCustomerAdmin],
  [Permission.ViewPetitionStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],
  [Permission.ViewPetitionCampaignStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],
  [Permission.ViewPetitionFundraiserStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],
  [Permission.ViewPetitionTeamStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],

  [Permission.ViewRecruitingStatistics]: [
    Role.CustomerAdmin,
    Role.HeadRecruiter,
    Role.Recruiter,
  ],

  [Permission.ViewCsvArchives]: [Role.CustomerAdmin],
  [Permission.ViewSupporterFeedback]: [Role.CustomerAdmin],
  [Permission.ViewTimeTracking]: [Role.CustomerAdmin],
  [Permission.ViewGamificationAchievements]: [Role.CustomerAdmin],

  [Permission.ViewRecruitingTeams]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingJobScouts]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingJobApplications]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingCampaigns]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingSettings]: [Role.CustomerAdmin],

  [Permission.ViewUserSettings]: [Role.CustomerAdmin],
  [Permission.ViewLocationSettings]: [Role.CustomerAdmin],
  [Permission.ViewCampaignSettings]: [Role.CustomerAdmin],
  [Permission.ViewPetitionCampaignSettings]: [Role.CustomerAdmin],
  [Permission.ViewWorkShiftSettings]: [Role.CustomerAdmin],
  [Permission.ViewBlackListItemSettings]: [Role.CustomerAdmin],

  [Permission.ViewTrainingcenterGroups]: [
    Role.AgencyAdminOne,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.EditTrainingcenterGroups]: [
    Role.AgencyAdminOne,
    Role.CustomerAdmin,
  ],
  [Permission.ViewTrainingcenterQuizzes]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.EditTrainingcenterQuizzes]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewTrainingcenterQuizReports]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],

  [Permission.ViewTrainingcenterQuizReportAttempts]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewAdminInterface]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.HeadRecruiter,
    Role.JobScout,
    Role.Recruiter,
    Role.TeamLeader,
  ],

  // Next Permissions
  [Permission.ViewNext]: [
    Role.Fundraiser,
    Role.Coach,
    Role.TeamLeader,
    Role.JobScout,
  ],

  [Permission.ViewLauncherFundraiserStatistics]: [
    Role.Fundraiser,
    Role.Coach,
    Role.TeamLeader,
    Role.JobScout,
  ],

  [Permission.ViewLauncherTeamStatistics]: [Role.Coach, Role.TeamLeader],

  // Cokcpit Permission
  [Permission.ViewCockpit]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.HeadRecruiter,
    Role.Organization,
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
    Role.Recruiter,
    Role.TeamLeader,
  ],
});

/**
 * Util service that checks if a user (`AuthInfo`) has sufficient
 * permissions.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  /**
   * Checks if the given `authInfo` fulfills any of the given permissions.
   *
   * ***NOTE***
   * Make sure to pass permissions, otherwise an error will be thrown.
   *
   * @param authInfo
   * @param permissions Permissions to check. There must be at least 1 item.
   * @returns
   * `true` if `authInfo` fulfills some permission, otherwise false.
   */
  public hasSome(authInfo: AuthInfo, permissions: Permission[]): boolean {
    if (permissions.length === 0) {
      throw new Error(
        'Passed permissions are empty. You must pass at least one permission.',
      );
    }

    const roles = rolesForAuthInfo(authInfo);
    for (const permission of permissions) {
      const allowedRoles = this.getRolesForPermission(permission);
      // first permission that is fulfilled, return true
      if (roles.some((r) => allowedRoles.has(r))) {
        return true;
      }
    }

    return false;
  }

  /**
   * Checks if the given `authInfo` fulfills every of the given permissions.
   *
   * ***NOTE***
   * Make sure to pass permissions, otherwise an error will be thrown.
   *
   * @param authInfo
   * @param permissions Permissions to check. There must be at least 1 item.
   * @returns
   * `true` if `authInfo` fulfills every permission, otherwise false.
   */
  public hasEvery(authInfo: AuthInfo, permissions: Permission[]): boolean {
    if (permissions.length === 0) {
      throw new Error(
        'Passed permissions are empty. You must pass at least one permission.',
      );
    }

    const roles = rolesForAuthInfo(authInfo);
    for (const permission of permissions) {
      const allowedRoles = this.getRolesForPermission(permission);
      // first permission that isn't fulfilled, return false
      if (!roles.some((r) => allowedRoles.has(r))) {
        return false;
      }
    }

    return true;
  }

  public hasPermission(authInfo: AuthInfo, permission: Permission): boolean {
    return this.hasSome(authInfo, [permission]);
  }

  /**
   * @param permission Permission for which the roles should be passed.
   * @returns
   * Roles that fulfill this permission. If the permission is unknown,
   * an error is thrown.
   */
  private getRolesForPermission(permission: Permission): Set<Role> {
    const allowedRoles = ROLES_PER_PERMISSION.get(`${permission}`);
    if (!allowedRoles) {
      throw new Error(`Unknown permission ${permission}`);
    }
    return allowedRoles;
  }
}
