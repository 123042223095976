import { inject, Injectable } from '@angular/core';
import { LocalStorageProvideFactory } from '@app/data/storage-accessor';
import { AuthInfo } from '@fmnts/api/auth';
import { isNotNull } from '@fmnts/core';
import {
  AuthApiActions,
  AuthRootActions,
  selectAuth,
  selectSession,
} from '@fmnts/shared/auth/data-access';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, EMPTY, filter, of, tap } from 'rxjs';

interface AuthData {
  authInfo?: AuthInfo | null;
  access_token?: string | null;
  refresh_token?: string | null;
}

/**
 * Effects for persisting session tokens for page reloads.
 * This functions as a bridge to make the session token available to the old frontend.
 */
@Injectable()
export class AuthTokenEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);

  /**
   * Provides access for persistent storage for the session tokens.
   */
  private readonly _storage = LocalStorageProvideFactory<AuthData | null>(
    // ***NOTE***: This is the key that the old frontend uses to get session tokens
    'ngStorage-tokenData',
    {
      authInfo: null,
      access_token: null,
      refresh_token: null,
    },
  )();

  hydrate$ = createEffect(() => {
    return this.actions$.pipe(ofType(ROOT_EFFECTS_INIT)).pipe(
      concatMap(() => {
        const { access_token, authInfo, refresh_token } =
          this._storage.persisted() ?? {};

        if (access_token && refresh_token && authInfo) {
          return of(
            AuthRootActions.hydrate({
              authInfo,
              session: { access_token, refresh_token },
            }),
          );
        }

        return EMPTY;
      }),
    );
  });

  persistTokens$ = createEffect(
    () => {
      return this.store.select(selectSession).pipe(
        filter(isNotNull),
        tap((session) => {
          this._storage.merge({
            access_token: session.access_token,
            refresh_token: session.refresh_token,
          });
        }),
      );
    },
    { dispatch: false },
  );

  persistAuthInfo$ = createEffect(
    () => {
      return this.store.select(selectAuth).pipe(
        filter(isNotNull),
        tap((authInfo) => {
          this._storage.merge({ authInfo });
        }),
      );
    },
    { dispatch: false },
  );

  clearPersisted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthApiActions.unauthenticate.success,
          AuthApiActions.unauthenticate.failure,
          AuthRootActions.forgetUser,
        ),
        tap(() => {
          this._storage.removePersisted();
        }),
      );
    },
    { dispatch: false },
  );
}
