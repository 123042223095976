export enum Permission {
  // Cockpit
  ViewCockpit,

  Login,

  ViewDashboard,
  ViewDashboardActivity,
  ViewDashboardPerformance,

  ViewActivities,
  ViewActivitiesDonations,
  ViewActivitiesAchievements,
  ViewActivitiesDonorFeeback,

  ViewTeamList,
  ViewTeam,
  CreateTeam,
  EditTeam,
  DeleteTeam,

  ViewFundraiserList,
  ViewFundraiser,
  CreateFundraiser,
  EditFundraiser,
  PromoteFundraiserToCoach,
  PromoteFundraiserToTeamLeader,
  DeleteFundraiser,

  ViewDonationList,
  ViewDonation,
  CreateDonation,
  EditDonation,
  DeleteDonation,

  ViewUnfinishedDonationList,
  ViewUnfinishedDonation,
  CreateUnfinishedDonation,
  EditUnfinishedDonation,
  DeleteUnfinishedDonation,

  ViewDonationStatistics,
  ViewTeamStatistics,
  ViewFundraiserStatistics,
  ViewCampaignStatistics,
  ViewLocationStatistics,

  ViewLocationList,
  ViewLocation,
  EditLocation,
  ViewLocationCommentList,
  CreateLocationComment,
  ViewLocationReporting,
  CreateLocation,

  ViewDonorFeedbackEvaluation,
  EditDonorFeedbackComment,

  ViewPetitions,
  ViewPetitionStatistics,
  ViewPetitionCampaignStatistics,
  ViewPetitionFundraiserStatistics,
  ViewPetitionTeamStatistics,

  ViewRecruitingStatistics,

  ViewCsvArchives,
  ViewSupporterFeedback,
  ViewTimeTracking,
  ViewGamificationAchievements,

  ViewRecruitingTeams,
  ViewRecruitingJobScouts,
  ViewRecruitingJobApplications,
  ViewRecruitingCampaigns,
  ViewRecruitingSettings,

  ViewUserSettings,
  ViewLocationSettings,
  ViewCampaignSettings,
  ViewPetitionCampaignSettings,
  ViewWorkShiftSettings,
  ViewBlackListItemSettings,

  ViewTrainingcenterGroups,
  EditTrainingcenterGroups,
  ViewTrainingcenterQuizzes,
  EditTrainingcenterQuizzes,
  ViewTrainingcenterQuizReports,
  ViewTrainingcenterQuizReportAttempts,

  ViewAdminInterface,

  // Next
  ViewNext,
  ViewLauncherFundraiserStatistics,
  ViewLauncherTeamStatistics,
}
