import { rolesForAuthInfo } from '@fmnts/api/auth';
import { createSelector } from '@ngrx/store';
import { authFeature } from './auth.reducer';

export const {
  selectAuthState, // feature selector
  selectAuth,
  selectIsAuthenticating,
  selectSession,
} = authFeature;

/**
 * Selector for the current authenticated state.
 */
export const selectIsAuthenticated = createSelector(
  selectSession,
  (session) => !!session?.access_token,
);

/**
 * Selects roles for the current user.
 */
export const selectRoles = createSelector(selectAuth, (auth) =>
  auth ? rolesForAuthInfo(auth) : [],
);
