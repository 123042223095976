import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api/util';
import { map, Observable } from 'rxjs';
import { AuthInfoAdapter } from './adapter/auth-info.adapter';
import {
  IApiUserDto,
  IApiUserPasswordUpdateDto,
} from './api-model/auth.api-model';
import { AuthInfo } from './model/auth-info';

export type IUserPasswordUpdateDto = IApiUserPasswordUpdateDto;

/**
 * Api client for handling user API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class UserApi {
  private readonly authInfoAdatper = new AuthInfoAdapter();

  constructor(
    private apiConfig: ApiConfigService,
    private http: HttpClient,
  ) {}

  /**
   * Fetches user information.
   *
   * @returns
   * An `Observable` that emits with the user information, then completes.
   */
  public fetchAuthInfo(): Observable<AuthInfo> {
    const url = this.apiConfig.buildCockpitApiUrl(['/v1/me/']);

    return this.http
      .get<IApiUserDto>(url)
      .pipe(map((data) => this.authInfoAdatper.adapt(data)));
  }

  /**
   * Updates the current users password.
   *
   * @param dto DTO describing the change
   *
   * @returns
   * An `Observable` that emits when the change was OK.
   */
  public updatePassword(dto: IUserPasswordUpdateDto): Observable<void> {
    const url = this.apiConfig.buildCockpitApiUrl(['/v2/me/password/']);

    return this.http.post(url, dto).pipe(map(() => {}));
  }
}
