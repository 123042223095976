import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './+state/auth.effects';
import { authFeature } from './+state/auth.reducer';
import { UserApiEffects } from './+state/user-api.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects, UserApiEffects]),
  ],
})
export class SharedAuthDataAccessModule {}
