import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appFeatureFlagCanActivate } from '@app/core/config/app-feature-flag.guard';
import { ExternalUrlResolver, RootRedirect } from '@app/shared/infra';
import {
  BlankComponent,
  NotFoundComponent,
  UnauthorizedComponent,
} from '@app/shared/ui';
import { AuthGuard } from '@fmnts/shared/auth/infra';
import {
  AwaitCustomerGuard,
  AwaitCustomerInitializedGuard,
} from '@fmnts/shared/customer/infra';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RootRedirect],
    component: NotFoundComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@app/auth/feature-auth-shell').then((m) => m.authShellRoutes),
  },
  {
    path: 'app',
    canActivate: [appFeatureFlagCanActivate({ flags: ['next'] }), AuthGuard],
    component: BlankComponent,
    resolve: { url: ExternalUrlResolver },
  },
  {
    path: 'next',
    canActivate: [
      appFeatureFlagCanActivate({ flags: ['next'] }),
      AuthGuard,
      AwaitCustomerGuard,
    ],
    loadChildren: () =>
      import('@app/next/next.module').then((m) => m.NextModule),
  },
  {
    path: 'cockpit',
    canActivate: [AuthGuard, AwaitCustomerInitializedGuard],
    loadChildren: () =>
      import('@app/cockpit/cockpit.module').then((m) => m.CockpitModule),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('@app/verify/feature/shell').then((m) => m.verifyShellRoutes),
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Enable anchor scrolling for presentations
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
