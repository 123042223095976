import { Injectable } from '@angular/core';
import {
  ApiTokenResponse,
  AuthClient,
  AuthInfo,
  AuthInfoAdapter,
} from '@fmnts/api/auth';
import { Store } from '@ngrx/store';
import { Observable, catchError, forkJoin, map, mergeMap, of } from 'rxjs';
import { selectAuth } from './+state/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authInfoAdatper = new AuthInfoAdapter();

  private readonly auth$ = this.store.select(selectAuth);
  private _auth: AuthInfo | null = null;

  constructor(
    private store: Store,
    private authClient: AuthClient,
  ) {
    this.auth$.subscribe((auth) => {
      this._auth = auth;
    });
  }

  /**
   * @deprecated
   * use
   * ```
   * class Service {
   *  private readonly auth$ = this.store.select(selectAuth);
   *
   *  constructor(private store: Store) { }
   * }
   * ```
   *
   * @returns
   * Stored authentication data.
   */
  public getAuthInfo(): AuthInfo {
    return this._auth ?? this.authInfoAdatper.makeEmpty();
  }

  public authenticate(
    username: string,
    password: string,
  ): Observable<ApiTokenResponse> {
    return this.authClient.authenticate(username, password).pipe(
      mergeMap((response) =>
        forkJoin([
          this.authClient.authenticateWithAdmin(response.access_token),
        ]).pipe(
          catchError(() => of(false)),
          map(() => response),
        ),
      ),
    );
  }

  public unauthenticate(accessToken: string): Observable<void> {
    return this.authClient.revoke(accessToken).pipe(
      mergeMap((response) =>
        this.authClient.unauthenticateWithAdmin().pipe(
          catchError(() => of(false)),
          map(() => response),
        ),
      ),
    );
  }
}
