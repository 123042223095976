export enum Role {
  AgencyAdminOne = 'Agency Admin One',
  CallingAgent = 'Calling Agent',
  CampaignManager = 'Campaign Manager',
  Coach = 'Coach',
  CustomerAdmin = 'Customer Admin',
  Fundraiser = 'Fundraiser',
  HeadRecruiter = 'Head Recruiter',
  JobScout = 'Job Scout',
  Organization = 'Organization',
  PetitionCampaignManager = 'Petition Campaign Manager',
  PetitionCustomerAdmin = 'Petition Customer Admin',
  Recruiter = 'Recruiter',
  TeamLeader = 'Team Leader',
}
export type Roles = `${Role}`;
