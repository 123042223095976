import { Injectable } from '@angular/core';
import { AuthClient } from '@fmnts/api/auth';
import { createHttpRequestEffectHandler } from '@fmnts/common/store';
import { fromHttpOrGeneralError } from '@formunauts/shared/errors/domain';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, exhaustMap, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { AuthApiActions, AuthLoginPageActions } from './auth.actions';
import { selectSession } from './auth.selectors';

/**
 * Effects related to OAuth.
 */
@Injectable()
export class AuthEffects {
  /**
   * Authenticates user via username / password.
   */
  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.authenticate.request, AuthLoginPageActions.login),
      switchMap(
        createHttpRequestEffectHandler(AuthApiActions.authenticate, {
          run: ({ username, password }) =>
            this.authService.authenticate(username, password),
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  /**
   * Refreshes the token.
   */
  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.refresh.request),
      concatLatestFrom((action) => this.store.select(selectSession)),
      exhaustMap(
        createHttpRequestEffectHandler(AuthApiActions.refresh, {
          run: ([_, session]) =>
            session?.refresh_token
              ? this.authClient.refresh(session.refresh_token)
              : EMPTY,
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  /**
   * Revokes the token if available.
   */
  unauthenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.unauthenticate.request),
      concatLatestFrom((action) => this.store.select(selectSession)),
      exhaustMap(
        createHttpRequestEffectHandler(AuthApiActions.unauthenticate, {
          run: ([_, session]) =>
            session?.access_token
              ? this.authService.unauthenticate(session.access_token)
              : EMPTY,
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private authClient: AuthClient,
    private authService: AuthService,
    private store: Store,
  ) {}
}
