import { AuthInfo, AuthSession } from '@fmnts/api/auth';
import { Option } from '@fmnts/core';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import {
  AuthApiActions,
  AuthLoginPageActions,
  AuthRootActions,
} from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthFeatureState {
  auth: Option<AuthInfo>;
  isAuthenticating: boolean;
  session: Option<AuthSession>;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthFeatureState;
}

export const initialAuthFeatureState: AuthFeatureState = {
  auth: null,
  isAuthenticating: false,
  session: null,
};

export const authFeature = createFeature({
  name: AUTH_FEATURE_KEY,
  reducer: createReducer(
    initialAuthFeatureState,
    on(
      AuthApiActions.authenticate.request,
      AuthLoginPageActions.login,
      produce((draft) => {
        draft.isAuthenticating = true;
      }),
    ),
    on(
      AuthApiActions.authenticate.success,
      AuthApiActions.refresh.success,
      produce((draft, { payload: session }) => {
        draft.isAuthenticating = false;
        draft.session = session;
      }),
    ),
    on(
      AuthApiActions.authenticate.failure,
      AuthApiActions.refresh.failure,
      AuthApiActions.authInfo.failure,
      produce((draft) => {
        draft.isAuthenticating = false;
      }),
    ),
    on(
      AuthApiActions.authInfo.success,
      produce((draft, { payload: auth }) => {
        draft.isAuthenticating = false;
        draft.auth = auth;
      }),
    ),
    on(
      AuthApiActions.unauthenticate.success,
      AuthApiActions.unauthenticate.failure,
      AuthRootActions.forgetUser,
      produce((draft) => {
        draft.session = null;
        draft.auth = null;
      }),
    ),
    on(
      AuthRootActions.hydrate,
      produce((draft, { authInfo, session }) => {
        draft.auth = authInfo;
        draft.session = session;
      }),
    ),
  ),
});

export const { reducer: authFeatureReducer } = authFeature;
