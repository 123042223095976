import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthInfo, Permission } from '@fmnts/api/auth';
import {
  PermissionService,
  selectAuth,
  selectIsAuthenticated,
} from '@fmnts/shared/auth/data-access';
import { Store } from '@ngrx/store';
import { combineLatest, map, type Observable } from 'rxjs';

/**
 * Interface for configuration that can be passed
 * to the `data` of a route.
 */
interface AuthGuardRouteData {
  /**
   * If set to `true`, user must not be authenticated.
   */
  requireUnauthenticated?: boolean;
  /**
   * Permission that is required to access the route
   */
  requiredPermission?: Permission;
  /**
   * A route that is redirected to, in case that the required
   * permission is not fulfilled.
   */
  fallbackRoute?: string;
}

/**
 * Guard that checks if the user is currently authenticated
 * and emits with `true` or `false`.
 */
export function isAuthenticatedGuard(
  store = inject(Store),
): Observable<boolean> {
  return store.select(selectIsAuthenticated);
}

/**
 * Guards a route or its children.
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private readonly isAuthenticated$ = this.store.select(selectIsAuthenticated);
  private readonly auth$ = this.store.select(selectAuth);

  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const routeConfig = route.data ?? {};

    return combineLatest([this.isAuthenticated$, this.auth$]).pipe(
      map(([isAuthenticated, auth]) =>
        this._redirect(isAuthenticated, auth, routeConfig, state.url),
      ),
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }

  private _redirect(
    isAuthenticated: boolean,
    auth: AuthInfo | null,
    config: AuthGuardRouteData,
    redirectTo: string,
  ) {
    const { requireUnauthenticated, requiredPermission, fallbackRoute } =
      config;

    if (!isAuthenticated && !requireUnauthenticated) {
      return this.router.createUrlTree(['/auth/login'], {
        queryParams: {
          redirectTo,
        },
      });
    }

    if (!requiredPermission) {
      return true;
    }

    if (
      !auth ||
      !this.permissionService.hasPermission(auth, requiredPermission)
    ) {
      return fallbackRoute
        ? this.router.createUrlTree([fallbackRoute])
        : this.router.createUrlTree(['/unauthorized'], {
            queryParams: { url: redirectTo },
          });
    }

    return true;
  }
}
